<template>
<section id="profile-splash" >
    <h1>Profile Splash</h1>
    <!-- <img src="@/assets/images/just_wave_optimized@3x.png" id='splash'/> -->
    <!-- require('@/assets/images/icons/mandala_icon.svg' -->
    </section>
</template>


<script>
export default {};
</script>


<style scoped>



</style>
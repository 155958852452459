<template>
  <scrollactive class="footer-nav">
    <ul class="list-reset">
      <li>
        <a href="#top-of-page" class="scrollactive-item"
          >Home</a
        >
      </li>
      <li>
        <a href="#at-a-glance-container" class="scrollactive-item"
          >At A Glance</a
        >
      </li>
      <li>
        <a href="#showcase-container" class="scrollactive-item"
          >Recent Projects</a
        >
      </li>
      <li>
                <a href="#contact-container" class="scrollactive-item">Contact</a>
        
      </li>

    </ul>
  </scrollactive>
</template>

<script>
export default {
  name: "CFooterNav",
};
</script>

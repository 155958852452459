<template>
  <div class="footer-social icons-vertical-center">
    <ul class="list-reset rainbow-backed">
      <li>
        <a href="mailto:reespawson@gmail.com">
          <img
            src="@/assets/images/icons/mail_con.svg"
            width="32"
            height="32"
            class="flip-color"
          />
        </a>
      </li>

      <li>
        <a href="https://github.com/KidCon">
          <img
            src="@/assets/images/icons/svg_socials/039-github.svg"
            width="32"
            height="32"
            class="flip-color"
          />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/rees-pawson-1a11031a5/">
          <img
            src="@/assets/images/icons/svg_socials/031-linkedin.svg"
            width="32"
            height="32"
            class="flip-color"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CFooterSocial",
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"features-split section",class:[
    _vm.topOuterDivider && 'has-top-divider',
    _vm.bottomOuterDivider && 'has-bottom-divider',
    _vm.hasBgColor && 'has-bg-color',
    _vm.invertColor && 'invert-color' ]},[_c('div',{staticClass:"container",attrs:{"id":"showcase-container"}},[_c('div',{staticClass:"features-split-inner section-inner",class:[
        _vm.topDivider && 'has-top-divider',
        _vm.bottomDivider && 'has-bottom-divider' ]},[_c('c-section-header',{staticClass:"center-content",attrs:{"data":_vm.sectionHeader}}),_c('div',{staticClass:"split-wrap",class:[
          _vm.invertMobile && 'invert-mobile',
          _vm.invertDesktop && 'invert-desktop',
          _vm.alignTop && 'align-top' ]},[_c('div',{staticClass:"split-item"},[_vm._m(0),_c('div',{staticClass:"split-item-image reveal-from-left",class:_vm.imageFill && 'split-item-image-fill',attrs:{"data-reveal-container":".split-item"}},[_c('c-image',{attrs:{"src":require('@/assets/videos/BOTGAR_SAMPLE.png'),"alt":"BOTGAR","width":528,"height":396}})],1)]),_c('div',{staticClass:"split-item"},[_vm._m(1),_c('div',{staticClass:"split-item-give-thanks reveal-from-right center-content",class:_vm.imageFill && 'split-item-image-fill',attrs:{"data-reveal-container":".split-item"}},[_c('c-image',{attrs:{"src":require('@/assets/images/igivethanks.png'),"alt":"Features split image 02","width":400,"height":700}})],1)])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"split-item-content center-content-mobile reveal-from-right justify-text-right text-xs",attrs:{"data-reveal-container":".split-item"}},[_c('h3',{staticClass:"mt-0 mb-12"},[_vm._v("BotgAR (2020)")]),_c('ul',{staticClass:"remove-bullets"},[_c('li',[_vm._v("Botanical Gardens Augmented Reality")]),_c('li',[_vm._v("Android Application developed in Unity")]),_c('li',[_c('a',{attrs:{"href":"https://www.qut.edu.au/research/study-with-us/student-topics/topics/augmented-sociality-enabling-a-participatory-experience-of-augmented-reality","target":"_blank"}},[_vm._v(" Developed as part of QUT's 'Augmented Sociality' Research")])]),_c('li',[_c('a',{attrs:{"href":"https://github.com/KidCon/BOTGAR","target":"_blank"}},[_vm._v("Code Available on Github")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"split-item-content center-content-mobile reveal-from-left text-xs",attrs:{"data-reveal-container":".split-item"}},[_c('h3',{staticClass:"mt-0 mb-12"},[_vm._v("iGiveThanks (2020)")]),_c('ul',{staticClass:"remove-bullets"},[_c('li',[_vm._v(" Available at: "),_c('a',{attrs:{"href":"http://www.igivethanks.co","target":"_blank"}},[_vm._v("www.igivethanks.co")])]),_c('li',[_vm._v("Vue Application with Google Firebase backend")]),_c('li',[_vm._v("Developed as part of the 2020 NextGen Startup Weekend")]),_c('li',[_c('a',{attrs:{"href":"https://twitter.com/OQCE_/status/1239117083146764289?s=20","target":"_blank"}},[_vm._v("Overall weekend finalist")])]),_c('li',[_c('a',{attrs:{"href":"https://github.com/lutzenburg/APPreciation","target":"_blank"}},[_vm._v("Code Available on Github")])])])])}]

export { render, staticRenderFns }